@import "../../assets/css/mixin.scss";

.status {
  margin-top: 60px;
  &__bottom {
    @include flex;
    max-width: 368px;
    margin-top: 18px;
    gap: 8px;
    &_box {
      @include flex-center;
      flex-wrap: nowrap;
      height: 48px;
      width: 180px;
      padding: 8px 0;
      gap: 0 6px;
      border-radius: 8px;
      box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%),
        0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%);
      background: $primaryLight;
      &_input {
        width: 30px;
        padding: 3px;
        border-radius: 6px;
        background: #fff;
      }
    }
  }
}
