@import "../../assets/css/mixin.scss";

.data {
  margin-top: 60px;
  &_wrap {
    padding-bottom: 6px;
    overflow-x: scroll;
    &_contents {
      min-width: 842px;
    }
  }
  &_row {
    display: flex;
    gap: 0 42px;
  }
  &_list {
    display: flex;
    width: 460px;
    align-items: center;
    gap: 0 30px;
    &_label {
      width: 140px;
      margin-top: 14px;
      padding: 8px 0;
      text-align: center;
      border-radius: 8px;
      box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%),
        0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%);
      background: $primaryLight;
    }
    &_input {
      width: 280px;
    }
  }
  &_category {
    max-width: 160px;
  }
}
