@import "mixin";

a {
  text-decoration: none;
  transition: all 0.2s;

  &:hover {
    color: #888;
  }
}

li {
  list-style-type: none;
}

body {
  font-variant-ligatures: none;
  font-family: "游明朝", "Yu Mincho", "游明朝体", "YuMincho", serif;
  overflow-x: hidden;
  color: $black;
  font-weight: 500;
}

.gothic {
  font-family: "游ゴシック体", "YuGothic", "游ゴシック", "Yu Gothic", sans-serif;
}

img {
  max-width: 100%;
}

.none {
  display: none;
}

.pc {
  display: block !important;
  @include tab {
    display: none !important;
  }
}

.tab {
  display: none !important;
  @include tab {
    display: block !important;
  }
}

.flex {
  @include flex;
}

.flex-row {
  display: flex;
  flex-wrap: wrap;
}

.flex-center {
  @include flex-center;
}

.flex-around {
  @include flex-around;
}

.container {
  width: 100%;
  max-width: 1280px;
  @include center;
  padding: 0 40px;
  @include pc {
    padding: 0 calc(40vw / (1280 / 100));
  }
}

.main {
  padding: 160px 0;
}

.page-title {
  font-size: 24px;
  text-align: center;
  font-family: "游ゴシック体", "YuGothic", "游ゴシック", "Yu Gothic", sans-serif;
}

.section-wrap {
  margin-top: 60px;
}

.section-title {
  font-size: 20px;
  font-family: "游ゴシック体", "YuGothic", "游ゴシック", "Yu Gothic", sans-serif;
}

.page-logo {
  margin-bottom: 8px;
  text-align: center;
}

.section-container {
  margin-top: 20px;
  padding: 18px 16px;
  border: 1px solid #c0c0c0;
  border-radius: 12px;
  box-shadow: 0px 1px 2px -1px rgb(0 0 0 / 20%),
    0px 1px 3px 0px rgb(0 0 0 / 14%), 0px 2px 2px 0px rgb(0 0 0 / 12%);
}
